import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-276363df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-container" }
const _hoisted_2 = { class: "dashboard-sections" }
const _hoisted_3 = { class: "right-side" }
const _hoisted_4 = { class: "left-side" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["WelcomeSection"]),
    ($setup.loading)
      ? (_openBlock(), _createBlock($setup["Loader"], {
          key: 0,
          loading: $setup.loading
        }, null, 8, ["loading"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        false
          ? (_openBlock(), _createBlock($setup["PartsSummarySection"], {
              key: 0,
              partStatuses: $setup.partStatuses,
              onChangeOption: $setup.onChangeOption
            }, null, 8, ["partStatuses"]))
          : _createCommentVNode("", true),
        _createVNode($setup["QuotesSection"], {
          quoteList: $setup.quoteList,
          totalCount: $setup.totalCount
        }, null, 8, ["quoteList", "totalCount"]),
        _createVNode($setup["OrdersSection"], {
          orders: $setup.orders,
          totalCount: $setup.totalOrdersCount,
          quickFilters: $setup.quickFilters,
          onQuickFilterClicked: $setup.onQuickFilterClicked
        }, null, 8, ["orders", "totalCount", "quickFilters"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode($setup["UpdatesSection"])
      ])
    ])
  ]))
}